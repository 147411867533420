import styles from './css/Toolbar.module.css';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useQuery } from 'react-query';
import { getRecord } from '../../api/crud';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import IconContainer from '../../components/iconContainer/IconContainer';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Toolbar = ({ toggleDrawer }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { getUserId, logout } = useContext(AuthContext);
  const userId = getUserId();

  const { data: user, isLoading } = useQuery({
    queryFn: () => getRecord('user', userId),
    queryKey: ['logged-in-user-sidebar'],
  });

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-menu-popover' : undefined;

  return (
    <div className={styles['toolbar']}>
      <div className={styles['quick-links']}>
        <IconButton aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon color="action" />
        </IconButton>
        <ul className={styles['quick-nav']}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/leads">Leads</Link>
          </li>
          <li>
            <Link to="/profile">Profile</Link>
          </li>
        </ul>
      </div>
      <ul className={styles['toolbar-icons']}>
        <li className={styles['user-menu-icon']} onClick={toggleFullScreen}>
          {isFullScreen ? (
            <IconContainer isActive={true} icon={<FullscreenExitIcon />} />
          ) : (
            <IconContainer isActive={true} icon={<FullscreenIcon />} />
          )}
        </li>
        <li className={styles['logged-in-user']}>
          {isLoading ? (
            <>
              <Skeleton
                width={100}
                height={20}
                className={styles['name-skeleton']}
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className={styles['avatar-skeleton']}
              />
            </>
          ) : (
            <>
              <p
                className={styles['logged-in-user-name']}
              >{`${user?.firstName?.charAt(0)}. ${user?.lastName}`}</p>
              <Avatar
                src={user?.profilePicture}
                alt={`${user?.firstName} ${user?.lastName}`}
                className={styles['avatar']}
                onClick={handleAvatarClick}
                style={{ cursor: 'pointer' }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div className={styles['popover-content']}>
                  <Typography
                    variant="body1"
                    className={`${styles['current-user']} truncate-ellipsis`}
                  >
                    {`${user?.firstName} ${user?.lastName}`}
                  </Typography>
                  <Link to="/profile" className={styles['menu-item']}>
                    My Profile
                  </Link>
                  <button
                    onClick={logout}
                    className={styles['menu-item-button']}
                  >
                    Logout
                  </button>
                </div>
              </Popover>
            </>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Toolbar;
