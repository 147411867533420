import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styles from '../css/Tenders.module.css';
import Field from '../../../components/field/Field';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { updateRecord } from '../../../api/crud';
import tenderCategories from '../../../utils/data/tenderCategories';
import provinces from '../../../utils/data/provinces';
import departments from '../../../utils/data/tenderDepartments';
import { addDaysToDate } from '../../../utils/dateUtils';

const initialValues = {
  toDate: '',
  fromDate: '',
  category: [],
  province: '',
  department: '',
};

const TendersConfig = ({ isLoading, tenantId, data }) => {
  const [formData, setFormData] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const currentDate = new Date();

  useEffect(() => {
    setFormData({
      toDate: data?.tendersConfig?.toDate || addDaysToDate(currentDate, 90), // if the toDate does not exist, use 90 days from the current date as default
      fromDate:
        data?.tendersConfig?.fromDate ||
        currentDate.toISOString().split('T')[0], // if the fromDate does not exist, use the currentDate
      category: data?.tendersConfig?.category || [],
      province: data?.tendersConfig?.province || '',
      department: data?.tendersConfig?.department || '',
    });
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSave = async () => {
    setIsSubmitting(true);
    try {
      await toast.promise(
        updateRecord('tenant', tenantId, { tendersConfig: formData }),
        {
          pending: 'Updating...',
          success: 'Tender Config successfully updated!',
          error: 'Error updating Tender',
        }
      );
      queryClient.invalidateQueries(['company-info']);
      setFormData(initialValues); // Reset form after save
    } catch (error) {
      console.error('Error updating Tender config', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSave();
      }}
    >
      <h4 className={styles.header}>Tenders Configuration</h4>
      <div className={styles.formContainer}>
        <Field
          isLoading={isLoading}
          id="fromDate"
          label="From Date"
          name="fromDate"
          type="date"
          value={formData['fromDate']}
          onChange={handleInputChange}
        />
        <Field
          isLoading={isLoading}
          id="toDate"
          label="To Date"
          name="toDate"
          type="date"
          value={formData['toDate']}
          onChange={handleInputChange}
        />
        <Field
          isLoading={isLoading}
          id="department"
          label="Department"
          name="department"
          type="multi-select"
          options={departments}
          value={formData['department']}
          onChange={handleInputChange}
        />
        <Field
          isLoading={isLoading}
          id="province"
          label="Province"
          name="province"
          type="multi-select"
          options={provinces}
          value={formData['province']}
          onChange={handleInputChange}
        />
        <Field
          isLoading={isLoading}
          id="category"
          label="Category"
          name="category"
          type="multi-select"
          options={tenderCategories}
          value={formData['category']}
          onChange={handleInputChange}
        />
      </div>
      <Button
        size="small"
        type="submit"
        variant="contained"
        style={{ background: 'var(--primary-color)' }}
        disabled={isSubmitting || isLoading}
      >
        {isSubmitting ? 'Saving' : 'Save'}
      </Button>
    </form>
  );
};

export default TendersConfig;
